<template>
  <div class="my-dialog-mask" v-if="dialogVisible">
    <div class="my-dialog">
      <div class="header">
        <span>申请演示</span>
        <i class="el-icon-close" @click="dialogVisible = false"></i>
      </div>
      <div class="content">
        <div class="content-header">了解详情请拨打：13819580506</div>

        <div class="content-form">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-form-item label="如何称呼您" prop="name">
              <el-input v-model="form.name" placeholder="用于我们与您更好的沟通"></el-input>
            </el-form-item>
            <el-form-item label="企业或团队名称" prop="teamName">
              <el-input v-model="form.teamName" placeholder="用于我们更好的记录您的需求"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="form.phone" placeholder="用于我们更便捷的与您取得联系"></el-input>
            </el-form-item>
            <el-form-item label="备注信息">
              <el-input v-model="form.remark" placeholder="用于我们快速了解您想要了解的具体信息"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="w-full btn" @click="confirm" :loading="loading">立即预约</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 11位手机号正则验证
    const handlePhoneChange = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!regTel.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback()
        }
      }
    };
    return {
      loading: false,
      dialogVisible: false,
      form: {
        name: "",
        teamName: "",
        phone: "",
        remark: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        teamName: [
          { required: true, message: "请输入您的企业", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入您的联系电话", trigger: "blur" },
          { validator: handlePhoneChange, trigger: "blur" },
        ]
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.$http.post("/customerProspective/saveCustomProspective", this.form).then(res => {
            if (res.code === 200) {
              this.loading = false
              this.dialogVisible = false
              this.$message.success({
                message: "预约成功",
                offset: 80
              })
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my-dialog-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);

  .my-dialog {
    width: 800px;
    // height: 660px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;

    .header {
      width: 100%;
      height: 80px;
      font-size: 24px;
      font-weight: 600;
      line-height: 80px;
      text-align: center;
      color: #101010;
      background: url('../assets/imgs/index/dialog-bg.png') no-repeat center;

      .el-icon-close {
        position: absolute;
        right: 16px;
        top: 16px;
        font-size: 16px;
        color: #8C8C8C;
        cursor: pointer;
      }
    }

    .content {
      .content-header {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 24px;
        background: #0F73FF;
      }

      .content-form {
        width: 504px;
        margin: auto;
        .btn{
          margin: 24px 0;
        }
      }
    }
  }
}
</style>