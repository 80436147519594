import axios from 'axios'
import Vue from 'vue'
import {
  Message
} from 'element-ui';
const BASE_URL = process.env.VUE_APP_API_BASE_URL

// 创建 axios 实例
console.log('BASE_URL==', BASE_URL)
const request = axios.create({
  baseURL: BASE_URL,
  timeout: 5000 
})

request.interceptors.response.use(function (response) {
  const res = response.data
  if (res.code != 200) {
    Message({
      type: "error",
      message: res.msg
    })
  }
  return res
}, function (error) {
  return Promise.reject(error);
});


// 自定义dibootApi请求快捷方式
const dibootApi = {
  get (url, params, option = {}) {
    return request.get(url, {
      params,
      ...option
    })
  },
  post (url, data, responseType, option = {}) {
    return request({
      method: 'POST',
      url,
      data: JSON.stringify(data),
      responseType: responseType,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      ...option
    })
  },
  put (url, data, option = {}) {
    return request({
      method: 'PUT',
      url,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      ...option
    })
  },
  /**
   * 删除
   * @param url
   * @param params
   * @returns {AxiosPromise}
   */
  delete (url, params, option = {}) {
    return request({
      url,
      method: 'DELETE',
      params,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      withCredentials: true,
      ...option
    })
  },
  /***
   * 上传文件接口
   * @param url
   * @param formData
   * @returns {AxiosPromise}
   */
  upload (url, formData, option = {}) {
    return request({
      url,
      method: 'POST',
      data: formData,
      ...option
    })
  },
  /**
   * GET下载文件
   * @param url
   * @param data
   * @returns {AxiosPromise}
   */
  download (url, params, option = {}) {
    return request({
      url,
      method: 'GET',
      responseType: 'arraybuffer',
      observe: 'response',
      params,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      withCredentials: true,
      ...option
    })
  },
  /**
   * POST下载文件（常用于提交json数据下载文件）
   * @param url
   * @param data
   * @returns {AxiosPromise}
   */
  postDownload (url, data, option = {}) {
    return request({
      url,
      method: 'POST',
      responseType: 'arraybuffer',
      observe: 'response',
      data: JSON.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      withCredentials: true,
      ...option
    })
  },
  getDelete (url, params, option = {}) {
    console.log(params)
    return request({
      url,
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      withCredentials: true,
      ...option
    })
  }
}

Vue.prototype.$http = dibootApi

// export {
//   dibootApi
// }