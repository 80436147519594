var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-top"},[_c('div',{class:_vm.isDefault ? 'changeBg' : ''},[_c('header',{staticClass:"head_bar",class:_vm.defaultIndex == 0
          ? 'index0'
          : _vm.defaultIndex == 1
          ? 'index1'
          : _vm.defaultIndex == 2
          ? 'index2'
          : _vm.defaultIndex == 3
          ? 'index3'
          : _vm.defaultIndex == 4
          ? 'inde4'
          : 'index5'},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":_vm.imgUrl,"alt":""}})]),_c('div',{staticClass:"center",on:{"mouseleave":function($event){return _vm.hoverOut()}}},[_c('ul',{staticClass:"title_boxs"},_vm._l((_vm.titleList),function(item,index){return _c('li',{key:index,staticClass:"title_box",class:_vm.defaultIndex == index ? 'title_boxIn' : '',on:{"click":function($event){return _vm.routerJump(index)},"mouseenter":function($event){return _vm.hoverIn(index)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0),_c('transition',{attrs:{"name":"el-fade-in-linear"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatus),expression:"showStatus"}],staticClass:"second_bar",on:{"mouseenter":function($event){return _vm.hoverIn(1)},"mouseleave":function($event){return _vm.hoverOut()}}},[_c('div',{staticStyle:{"width":"100%","height":"4px","background-color":"transparent","margin-bottom":"4px"}}),_c('div',{staticClass:"second_bar_box"},_vm._l((_vm.secondList),function(item,index){return _c('div',{key:index},[(index == 0)?_c('div',{staticClass:"title_introduce"},[_c('div',{staticClass:"line"}),_c('h3',{staticClass:"h3_first"},[_vm._v("组合产品")])]):_vm._e(),(index == 3)?_c('div',{staticClass:"title_introduce"},[_c('div',{staticClass:"line"}),_c('h3',{staticClass:"h3_second"},[_vm._v("单个产品")])]):_vm._e(),_c('div',{staticClass:"select_part"},[_c('p',{class:item.path == _vm.currentPath ? 'selected' : '',on:{"click":function($event){return _vm.gotoPath(item.path)}}},[_vm._v(" "+_vm._s(item.title)+" ")])])])}),0)])])],1),_c('div',{staticClass:"right"},[_c('button',{on:{"click":function($event){_vm.$refs.requestPresentation.dialogVisible = true}}},[_vm._v("申请演示")])])])]),_c('request-presentation',{ref:"requestPresentation"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }