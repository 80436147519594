<template>
  <div id="app">
    <nav>
      <router-link to="/">
      </router-link>
    </nav>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {
    // console.log("this.selectIndex", this.selectIndex);
    this.$router.beforeEach((to, from, next) => {
      // console.log("跳转的路径", to.path);
      // console.log("来的路径", from);
      // if (to.path == "/softproduct") {
      // }
      next();
    });
  },
};
</script>

<style>
#app{
  overflow: auto;
}
</style>
