<template>
  <div class="page_bottom">
    <div class="center">
      <div class="content">
        <div class="left">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first">
              <div slot="label">软件产品</div>
              <div class="in_box in_box1">
                <span @click="goDetail('/scsl')" class="need_margin">智能实测实量</span>
                <span @click="goDetail('/zcyzl')" class="need_margin">智测云资料</span>
                <span @click="goDetail('/softproduct')">工程质量管理数智云</span>
              </div>
              <div class="in_box in_box2">
                <span @click="goDetail('/ylyzl')" class="need_margin">优量云资料</span>
                <span @click="goDetail('/dzcjgd')" class="need_margin">电子城建归档</span>
              </div>
              <div class="in_box">
                <span @click="goDetail('/xtqz')">协同电子签章</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="关于我们" name="second">
              <div class="in_box">
                <span class="need_margin" @click="goDetail('/aboutUs')">关于我们</span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="right">
          <img src="../assets/imgs/index/erwm.png" alt="" />
          <p>企业公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    goDetail(item) {
      if (this.$route.path === item) return
      this.$router.push({
        path: item,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page_bottom {
  width: 100%;
  height: 275px;
  background: #171b27;
  padding-top: 17px;
  .center {
    width: 1200px;
    height: 275px;
    margin: 0 auto;
    // border: 1px solid red;
    display: flex;
    justify-content: space-between;
    .content {
      width: 100%;
      height: 100%;
      //   display: flex;
      //   justify-content: space-between;
      position: relative;
      .left {
        width: 75%;
        position: absolute;

        .in_box {
          margin-bottom: 24px;
          text-align: left;
          span:hover {
            cursor: pointer;
            color: white;
          }
          .need_margin {
            display: inline-block;
            // margin-right: 65px;
            font-size: 16px;
            color: #999999;
            font-weight: 400;
          }
        }
        .in_box1 {
          .need_margin:nth-child(2) {
            margin-left: 96px;
            margin-right: 112px;
          }
        }
        .in_box2 {
          .need_margin:nth-child(2) {
            margin-left: 112px;
          }
        }
      }
      .right {
        position: absolute;
        margin: auto;
        top: 68px;
        right: 0px;
        width: 103px;
        height: 140px;
        img {
          width: 103px;
          height: 103px;
          cursor: default;
        }
        p {
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
  }
  ::v-deep .el-tabs__header {
    margin-bottom: 25px;
  }
  ::v-deep .el-tabs__item {
    color: #999;
    font-size: 20px;
    height: 60px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #fff;
  }
  ::v-deep .el-tabs__active-bar {
    height: 3px;
    background: #d9d9d9;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    background-color: #535353;
    height: 1px;
  }
  ::v-deep .el-tabs__nav-wrap {
    // padding-top: 17px;
  }
  ::v-deep .el-tabs__content {
    color: #999999;
    font-weight: 400;
    font-size: 16px;
  }
}
</style>
