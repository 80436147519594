<template>
  <div v-scroll class="main_page" @scroll="handleScroll">
    <!-- <el-backtop
      target=".page-component__scroll .el-scrollbar__wrap"
      :bottom="100"
    >
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          }
        "
      >
        UP
      </div>
    </el-backtop> -->
    <!-- <header class="head_bar">
      <div class="left">
        <img src="../assets/imgs/index/head_title.png" alt="" />
      </div>
      <div class="center">
        <ul class="title_boxs">
          <li class="title_box" v-for="(item, index) in titleList" :key="index">
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>
      <div class="right">
        <button>申请演示</button>
      </div>
    </header> -->
    <pagehead class="bgcolor" :isDefault="isDefault"></pagehead>
    <div class="company_poster">
      <div class="introduce">
        <div class="station_box" v-for="(item, index) in introduceList" :key="index">
          <div class="introduce_boxs">
            <div>
              <img :src="item.url" alt="" />
              <span>{{ item.title }}</span>
            </div>
            <p>{{ item.introduce }}</p>
          </div>
        </div>
      </div>
      <div class="companyInfo">
        <h1>豪米优量科技</h1>
        <!-- <h3>致力打造国内一流工程质量管理平台</h3> -->
        <h3>国内领先工程质量管理平台</h3>
        <h3>行业最佳电子档案智能平台</h3>
        <button class="ct" @click="$refs.requestPresentation.dialogVisible = true">申请演示</button>
      </div>
    </div>
    <div class="softwares">
      <div class="software_core">
        <div class="core_title">
          <h2>软件产品</h2>
          <p class="introduce">
            前沿新高技术，打通线下线上堵点，消解在线操作卡点，疏通外部资料接点，流程畅通清晰，智能辅助编辑，真正实现建设工程质量管理全过程全流程数字化。
          </p>
        </div>
        <div class="core_post">
          <img class="showIng" src="../assets/imgs/index/software.png" alt="" />
          <div class="post_content">
            <h2>工程质量数智管理平台</h2>
            <p>工程质量全过程数字化管理</p>
            <div class="post_box">
              <button class="btn_change" @click="goSort">
                查看详情
                <img class="img_set" src="../assets/imgs/index/rightIcon2.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
        <div class="produce_area">
          <div class="area_top">
            <div class="box" v-for="(item, index) in items1" :key="index">
              <img class="content_pic" :src="item.picUrl" alt="" />
              <div class="position_area">
                <h2>{{ item.title }}</h2>
                <p>{{ item.sketch }}</p>
                <button @click="goDetail(index)" class="btn_change">
                  查看详情
                  <img class="img_set" src="../assets/imgs/index/rightIcon2.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div class="area_bottom">
            <div class="box" v-for="(item, index) in items2" :key="index">
              <img class="content_pic" :src="item.picUrl" alt="" />
              <div class="position_area">
                <h2>{{ item.title }}</h2>
                <p>{{ item.sketch }}</p>
                <button class="btn_change" @click="goDetail(index + 3)">
                  查看详情
                  <img class="img_set" src="../assets/imgs/index/rightIcon2.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="four_status">
      <div class="center">
        <h3>解决市场四大现状问题</h3>
        <p class="statusIn">
          杭州豪米优量科技有限公司【豪米优量】专注于建设工程质量管理的数字化应用，深入的系统的运用计算机技术，实质性的改变工程质量管理领域手工化、无纸化、碎片化的粗浅状态，采用最前沿的人工智能技术、云技术、互联网技术构建完整的数字化质量管理系统，促进质量管理技术大进步，效率大提升，数据更真实，建立健全质量管理体系，形成工程质量大数据库。
        </p>
        <div class="details">
          <div class="detail_box" v-for="(item, index) in fourStatus" :key="index">
            <img :src="item.picUrl" alt="" />
            <h4>{{ item.title }}</h4>
            <h5>{{ item.problem }}</h5>
            <div class="paragraph">
              <p>{{ item.detail }}</p>
              <span class="round_dot"> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="servers">
      <div class="server_center">
        <h3>服务对象</h3>
        <div class="cards">
          <div class="card" v-for="(item, index) in services" :key="index">
            <img :src="item.cardUrl" alt="" />
            <div class="shadow_area" :class="index == 0 ? 'firstItem' : 'anotherItem'">
              <div class="shadow_top">
                <h4>{{ item.serviceType }}</h4>
                <div class="roles">
                  <div class="role" :class="
                      index == 0
                        ? 'first_in'
                        : index == 1
                        ? 'second_in'
                        : 'third_in'
                    " v-for="(item2, index2) in item.peopeleList" :key="index2">
                    <span>{{ item2 }}</span>
                  </div>
                </div>
              </div>
              <div v-for="(item, index2) in contents" :key="index2" class="shadow_bottom">
                <p v-if="index == index2">
                  {{ item.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperative_enterprise">
      <div class="center">
        <h3>与先进企业一起进步</h3>
        <div class="companys">
          <div class="company_box" v-for="(item, index) in companys1" :key="index">
            <img :src="item.companyLogo" alt="" />
            <h4>{{ item.companyName }}</h4>
          </div>
        </div>
        <div class="companys">
          <div class="company_box" v-for="(item, index) in companys2" :key="index">
            <img :src="item.companyLogo" alt="" />
            <h4>{{ item.companyName }}</h4>
          </div>
        </div>
      </div>
    </div>
    <request-presentation ref="requestPresentation" />
    <pagebottom></pagebottom>
  </div>
</template>

<script>
import requestPresentation from "@/components/requestPresentation.vue";
import pagebottom from "@/components/PageBottom.vue";
import pagehead from "@/components/PageTop.vue";
export default {
  components: {
    requestPresentation,
    pagebottom,
    pagehead,
  },
  data() {
    return {
      titleList: ["首页", "软件产品", "智能硬件", "帮助中心", "关于我们"],
      introduceList: [
        {
          url: require("../assets/imgs/index/classic.png"),
          title: "经典",
          introduce: "严谨而流畅、全面而简洁、强大而亲和、前卫有气质。",
        },
        {
          url: require("../assets/imgs/index/nature.png"),
          title: "生态",
          introduce: "同步最新规范、接口完整、各类资料均可导入导出线上签名盖章。",
        },
        {
          url: require("../assets/imgs/index/zn.png"),
          title: "智能",
          introduce: "大量的智能技术应用、快捷选取与编辑表、自动整理资料并归档。",
        },
        {
          url: require("../assets/imgs/index/yy.png"),
          title: "易用",
          introduce: "简洁流畅的窗口、熟悉的word和excel界面、任意调适的页面。",
        },
      ],
      items1: [
        {
          title: "智能实测实量",
          sketch: "智能硬件取代手工工具",
          picUrl: require("../assets/imgs/index/scsl.png"),
        },
        {
          title: "优量云资料",
          sketch: "白领资料员的标配",
          picUrl: require("../assets/imgs/index/yzl.png"),
        },
        {
          title: "协同电子签章",
          sketch: "全新电子审批模式",
          picUrl: require("../assets/imgs/index/xt.png"),
        },
      ],
      items2: [
        {
          title: "智测云资料",
          sketch: "项目部的最佳伴侣",
          picUrl: require("../assets/imgs/index/zcyzl.png"),
        },
        {
          title: "电子城建档案",
          sketch: "无纸化城建档案",
          picUrl: require("../assets/imgs/index/dzda.png"),
        },
      ],
      fourStatus: [
        {
          title: "手工操作",
          problem: "工作效率低",
          detail:
            "工程质量的检测验收和资料的编制整理尚停留在传统的手工阶段，工作效率较低。",
          picUrl: require("../assets/imgs/index/handscz.png"),
        },
        {
          title: "碎片化应用",
          problem: "不利于建设过程质量管理",
          detail:
            "各方主体零散地记录工程质量信息、数据没有实现互联互通，也不能形成工程质量大数据库，难以在工程使用期间追溯分析质量因。",
          picUrl: require("../assets/imgs/index/spyy.png"),
        },
        {
          title: "无纸化困境",
          problem: "无纸化处于低端状态",
          detail:
            "工程质量的数据记录仍要送纸质版申报、签字、盖章、归档，难以实现真正的无纸化办公。",
          picUrl: require("../assets/imgs/index/wzh.png"),
        },
        {
          title: "数据存疑",
          problem: "资料档案编造现象普遍",
          detail:
            "实测实量和资料编制脱节，人为因素和信用因素难以保证质量验收数据的准确，无法真实反映实际工程的状况。",
          picUrl: require("../assets/imgs/index/sjcy.png"),
        },
      ],
      contents: [
        {
          content:
            "资料云平台工地可办公、居家可办公、在途能办公秒速找到表格。智能辅助填写倍速完成,表格自动保存到施工技术资料、核查资料城建档案目录下瞬时归档赋页码。",
        },
        {
          content:
            "建设项目工程质量监管云平台和大数据库,可统计可分析能报警,准确监管和科学决策的依据来源,评优评先进数据支持,整改通知单准确到点。",
        },
        {
          content:
            "建设工程全过程质量管理体系云平台和大数据库，有记录可核查可追溯，落实质量管理责任制的有效载体。查询对比管理人员工作质量工作强度、项目、班组质量优劣自动排序，考核奖罚数据说话。",
        },
      ],
      services: [
        {
          serviceType: "项目级",
          peopeleList: ["资料员", "施工员", "质量员", "建造师", "项目经理"],
          cardUrl: require("../assets/imgs/index/item_level.png"),
        },
        {
          serviceType: "公司级",
          peopeleList: ["施工单位", "建设单位", "监理单位"],
          cardUrl: require("../assets/imgs/index/company_level.png"),
        },
        {
          serviceType: "地区级",
          peopeleList: ["质量监测站", "建设主管部门"],
          cardUrl: require("../assets/imgs/index/area_level.png"),
        },
      ],
      companys: [
        {
          companyName: "中铁十四局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "宝业集团股份有限公司",
          companyLogo: require("../assets/imgs/index/baoye.png"),
        },
        {
          companyName: "长业建设集团有限公司",
          companyLogo: require("../assets/imgs/index/changye.png"),
        },
        {
          companyName: "中国建筑第八工程局",
          companyLogo: require("../assets/imgs/index/zjbj.png"),
        },
        {
          companyName: "绍兴临空管委会",
          companyLogo: require("../assets/imgs/index/sxlk.png"),
        },
        {
          companyName: "浙江环宇建设集团有限公司",
          companyLogo: require("../assets/imgs/index/zjhy.png"),
        },
        {
          companyName: "中铁十一局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "浙江工程管理公司",
          companyLogo: require("../assets/imgs/index/gcgl.png"),
        },
        {
          companyName: "浙江建元工程管理有限公司",
          companyLogo: require("../assets/imgs/index/zjjy.png"),
        },
        {
          companyName: "绍兴市城市建设投资集团",
          companyLogo: require("../assets/imgs/index/sxct.png"),
        },
      ],
      companys1: [
        {
          companyName: "中铁十四局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "宝业集团股份有限公司",
          companyLogo: require("../assets/imgs/index/baoye.png"),
        },
        {
          companyName: "长业建设集团有限公司",
          companyLogo: require("../assets/imgs/index/changye.png"),
        },
        {
          companyName: "中国建筑第八工程局",
          companyLogo: require("../assets/imgs/index/zjbj.png"),
        },
        {
          companyName: "绍兴临空管委会",
          companyLogo: require("../assets/imgs/index/sxlk.png"),
        }
      ],
      companys2: [
        {
          companyName: "浙江环宇建设集团有限公司",
          companyLogo: require("../assets/imgs/index/zjhy.png"),
        },
        {
          companyName: "中铁十一局集团有限公司",
          companyLogo: require("../assets/imgs/index/zt14.png"),
        },
        {
          companyName: "浙江工程管理公司",
          companyLogo: require("../assets/imgs/index/gcgl.png"),
        },
        {
          companyName: "浙江建元工程管理有限公司",
          companyLogo: require("../assets/imgs/index/zjjy.png"),
        },
        {
          companyName: "绍兴市城市建设投资集团",
          companyLogo: require("../assets/imgs/index/sxct.png"),
        },
      ],
      status: false,
      scrollTop: 0,
      isDefault: false, //默认没滑动
    };
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        el.addEventListener("scroll", binding.value);
      },
      unbind: function (el, binding) {
        el.removeEventListener("scroll", binding.value);
      },
    },
  },
  created() {
    console.log("this.$router-homepage", this.$router.currentRoute);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function (event) {
      this.scrollTop = event.target.scrollTop;
      this.scrollTop?(this.isDefault = true):(this.isDefault = false);
       
    },
    goSort() {
      this.$router.push({
        path: "/softproduct",
      });
    },
    goDetail(index) {
      console.log(index);
      if (index == 0) {
        this.$router.push({
          path: "/scsl",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/ylyzl",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/xtqz",
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/zcyzl",
        });
      } else if (index == 4) {
        this.$router.push({
          path: "/dzcjgd",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  // 视口宽度小于1920px
  @media only screen and (max-width: 1920px) {
    .company_poster {
      background-position: center !important;
    }
    .cooperative_enterprise {
      background-position: center !important;
    }
  }
  @media only screen and (max-width: 1200px) {
  }
  // @media only screen and(min-height:0px) and (max-height: 1200px) {
  //   .slicing {
  //     margin-bottom: 20px;
  //   }
  //   .pages {
  //     margin-bottom: 20px;
  //   }
  // }
}
// body {
//   height: 100vh; /* 100%视口高度 */
//   margin: 0;
//   padding: 0;
//   overflow: hidden; /* 隐藏默认滚动条 */
// }
// /*设置滚动条的宽度和颜色*/
// ::-webkit-scrollbar {
//   width: 16px;
//   height: 10px;
//   background-color: #f1f1f1;
//   position: fixed;
//   z-index: 9999999;
//   overflow-y: auto;
// }
// // /*定义滚动条的滑块背景颜色*/
// ::-webkit-scrollbar-thumb {
//   background-color: #c1c1c1;
// }
// // /*设置滚动条的圆角*/
// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
// }
// // /*设置滚动条的轨道的背景色*/
// ::-webkit-scrollbar-track {
//   background-color: #f5f5f5;
// }
// // /*定义滚动条轨道的阴影效果*/
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
// // /*定义滚动条滑块如何响应鼠标事件*/
// ::-webkit-scrollbar-thumb:hover {
//   background-color: #b1b1b1;
// }
/* 设置向上滚动按钮样式 */
// ::-webkit-scrollbar-button:start {
//   background-color: #ccc; /* 按钮背景颜色 */
//   background-image: url('up-arrow.png'); /* 向上箭头图标 */
//   background-size: cover; /* 图标填充整个按钮 */
// }

// /* 设置向下滚动按钮样式 */
// ::-webkit-scrollbar-button:end {
//   background-color: #ccc; /* 按钮背景颜色 */
//   background-image: url('down-arrow.png'); /* 向下箭头图标 */
//   background-size: cover; /* 图标填充整个按钮 */
// }

.main_page {
  height: 100%;
  font-family: PingFang SC, PingFang SC;
  overflow: scroll;
  .head_bar {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 80px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    // background-color: rgba($color: #B6CCE3, $alpha: 0.5);

    .left {
      margin-right: 80px;
      cursor: pointer;
    }
    .center {
      .title_boxs {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        // color: #0f73ff;
        line-height: 21px;
        .title_box {
          width: 120px !important;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-content: center;
          padding-top: 28px;
          cursor: pointer;
          // span {
          //   border-bottom: 4px solid transparent;
          //   font-size: 16px;
          //   font-weight: 400;
          //   color: #3d424d;
          // }
        }
        .title_box:nth-child(1) {
          color: #0f73ff;
          span {
            border-bottom: 4px solid #0f73ff !important;
            font-size: 18px !important;
            font-weight: 600 !important;
            color: #0f73ff !important;
          }
        }
        // .title_box:hover {
        //   color: #0f73ff;
        // }
        // .title_box:hover span {
        //   border-bottom: 4px solid #0f73ff;
        //   font-size: 18px;
        //   font-weight: 600;
        //   color: #0f73ff;
        // }
        ul {
          display: flex;
        }
        li {
          height: 80px !important;
          text-align: center !important;
        }
      }
    }
    .right {
      position: absolute;
      top: 23px;
      right: 80px;
      button {
        width: 88px;
        height: 36px;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #0f73ff;
        background-color: transparent;
        font-size: 14px;
        font-weight: 400;
        color: #0f73ff;
        // cursor: pointer;
      }
    }
    .right:hover button {
      background-color: rgba($color: #0f73ff, $alpha: 0.8);
      color: white;
    }
  }
  .company_poster {
    background: url("../assets/imgs/index/index_bg.png") no-repeat;
    height: 640px;
    background-size: cover;
    background-position: 60% 12%;
    position: relative;
    .introduce {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      margin: auto;
      left: 0px;
      right: 0px;
      bottom: -80px;
      .station_box:hover .introduce_boxs {
        // position: relative;
        // top: -13px;
        margin-top: -15px;
        box-shadow: 0 2px 5px 2px rgba(#0084ff, 0.1);
      }
      .introduce_boxs {
        width: 274px;
        height: 118px;
        transition: top 2s ease;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 2px;
        opacity: 1;
        padding: 18px 12px 18px 18px;
        transition: all 0.2s;
        // border: 10px solid red;

        cursor: pointer;
        div {
          display: flex;
          align-items: center;
          margin-bottom: 11px;
          span {
            font-size: 20px;
            font-weight: 600;
            color: #3d424d;
          }
        }
        img {
          margin-right: 10px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: #718091;
          line-height: 22px;
        }
      }
    }
    .companyInfo {
      position: absolute;
      top: 200px;
      left: 455px;
      h1 {
        font-size: 40px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 600;
        color: #3d424d;
        margin-bottom: 24px;
      }
      h3 {
        font-size: 24px;
        font-weight: 400;
        color: #3d424d;
        line-height: 28px;
        margin-bottom: 16px;
      }
      button {
        width: 88px;
        height: 36px;
        margin-top: 32px;
        background: #0f73ff;
        border-radius: 2px;
        opacity: 1;
        text-align: center;
        box-sizing: border-box;
        border: none;
        color: white;
      }
      button:hover {
        background-color: rgba($color: #0f73ff, $alpha: 0.8);
      }
    }
  }
  .softwares {
    width: 100%;
    height: 1300px;
    background-color: #f5f6f8;
    padding-top: 178px;
    .post_box {
      display: flex;
      align-items: center;
    }
    .btn_change {
      border-radius: 4px;
      border: 1px solid #0e71ff;
      background-color: #fff;
      color: #0e71ff;
      font-size: 14px;
      font-weight: 400;
      padding: 8px;
      display: flex;
      align-items: center;
      align-content: center;
    }
    .img_set {
      color: #0e71ff;
      // background-color: #0e71ff;
      margin-left: 8px;
    }
    .software_core {
      margin: 0 auto;
      width: 1200px;
      height: 100%;
      p {
        font-size: 16px;
        font-weight: 400;
        color: #718091;
        margin-bottom: 28px;
      }
      h2 {
        font-size: 20px;
        font-weight: 400;
        color: #3d424d;
        margin-bottom: 16px;
      }
      .core_title {
        margin-bottom: 60px;
        h2 {
          font-size: 40px;
          font-weight: 600;
          color: #3d424d;
          text-align: center;
          margin-bottom: 28px;
        }
        p {
          margin: 0 auto;
          text-align: center;
          width: 912px;
          font-size: 16px;
          font-weight: 400;
          color: #718091;
          line-height: 28px;
        }
      }
      .core_post {
        width: 100%;
        height: 269px;
        //background-image: url("../assets/imgs/index/software.png");
        background-size: 100%, 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 43px;
        transition: transform 2s;
        .showIng {
          cursor: default;
        }
        .post_content {
          position: absolute;
          margin: auto;
          top: 50px;
          left: 60px;
          box-sizing: border-box;
          h2 {
            font-size: 32px;
            font-weight: 600;
            color: #3d424d;
            margin-bottom: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #718091;
            margin-bottom: 32px;
          }
        }
      }
      // .core_post:hover .showIng {
      //   transform: scale(1.2);
      // }
      .produce_area {
        width: 100%;
        .area_top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 45px;
          .box {
            width: 370px;
            height: 249px;
            background: #ffffff;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 20px;
            position: relative;
            transition: padding 0.3s, box-shadow 0.3s;
            img {
              cursor: default;
            }
            .content_pic {
              width: 100%;
              height: 100%;
            }
            .position_area {
              position: absolute;
              top: 44px;
              left: 30px;
            }
          }
          // .box:nth-child(1) {
          //   border-left: 3px solid transparent;
          // }
          .box:hover {
            padding: 0px;
            box-shadow: 0px 0px 3px 4px rgba(#0084ff, 0.1);
            //  border-left-color: rgb(0, 0, 0);
            // box-shadow: 1px 1px 2px 0px rgba(#0084ff, 0.1),
            //   -1px 1px 2px 0px rgba(#0084ff, 0.1),
            //   1px -1px 2px 0px rgba(#0084ff, 0.1),
            //   -1px -1px 2px 0px rgba(#0084ff, 0.1);
            // .area_top .box:nth-chi0ld(1) {
            //   border: none;
            // }
            // .area_top .box:nth-child(3) {
            //   border: none;
            // }
          }
        }
        // .area_top .box:nth-child(1) {
        //   border-left: 3px solid transparent;
        //   border-right: 3px solid transparent;
        // }
        // .area_top .box:nth-child(3) {
        //   border-left: 3px solid transparent;
        //   border-right: 3px solid transparent;
        // }
        .area_bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 45px;
          .box {
            width: 583px;
            height: 259px;
            background: #ffffff;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 20px;
            position: relative;
            transition: padding 0.3s, box-shadow 0.3s;
            img {
              cursor: default;
            }
            .content_pic {
              width: 100%;
              height: 100%;
            }
            .position_area {
              position: absolute;
              top: 44px;
              left: 54px;
              // img {
              //   width: 100%;
              //   height: 100%;
              // }
            }
          }
          .box:hover {
            padding: 0px;
            box-shadow: 0px 0px 3px 4px rgba(#0084ff, 0.1);
          }
        }
      }
    }
  }
  .four_status {
    height: 770px;
    box-sizing: border-box;
    padding-top: 88px;
    width: 100%;
    background: #1b325c;
    .center {
      margin: 0 auto;
      text-align: center;
      width: 1200px;
      color: white;
      h3 {
        width: 100%;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 27px;
      }
      .statusIn {
        width: 910px;
        margin: 0 auto;
        margin-bottom: 62px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
      .details {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        .detail_box {
          padding-top: 36px;
          height: 395px;
          width: 270px;
          box-sizing: border-box;
          background-color: rgba($color: #233b67, $alpha: 0.5);
          border-radius: 2px;
          transition: all 0.2s;
          img {
            margin-bottom: 40px;
          }
          h4 {
            margin-bottom: 15px;
            font-size: 24px;
            font-weight: 500;
          }
          h5 {
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 16px;
            color: #667695;
            line-height: 19px;
          }
          .paragraph {
            width: 180px;
            margin: 0 auto;
            text-align: left;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            .round_dot {
              position: absolute;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              margin: 0px;
              left: -16px;
              top: 8px;
            }
          }
        }
        .detail_box:hover {
          background-color: rgba($color: #0e71ff, $alpha: 0.8);
          cursor: pointer;
          h5 {
            color: #4f97ff;
          }
        }
      }
    }
  }
  .servers {
    width: 100%;
    height: 840px;
    background: #f5f6f8;
    box-sizing: border-box;
    padding-top: 100px;
    .server_center {
      width: 100%;
      h3 {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 50px;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: #3d424d;
      }
      .cards {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .card {
          width: 370px;
          height: 496px;
          box-sizing: border-box;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .shadow_area {
            box-sizing: border-box;
            padding-top: 20px;
            width: 100%;
            height: 155px;
            background: rgba(23, 27, 39, 0.8);
            position: absolute;
            margin: auto;
            left: 0px;
            right: 0px;
            bottom: 0px;
            overflow: hidden;
            transition: height ease-in-out 0.3s;
            .shadow_top {
              height: 155px;
            }
            .shadow_bottom {
              width: 250px;
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              margin: 0 auto;
              text-align: left;
              p {
                font-weight: 400;
                font-size: 16px;
                color: #ffffff;
                line-height: 22px;
              }
            }
            cursor: pointer;
            h4 {
              font-size: 24px;
              font-weight: 400;
              color: #fff;
              text-align: center;
              margin-bottom: 30px;
            }
            .roles {
              width: 250px;
              margin: 0 auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              .role {
                // margin-right: 16px;
                margin-bottom: 12px;
                span {
                  text-align: center;
                  line-height: 24px;
                  display: inline-block;
                  width: 72px;
                  height: 24px;
                  background: #ffffff;
                  border-radius: 2px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #718091;
                }
              }
            }
          }
          .shadow_area:hover {
            height: 310px;
          }
          // .shadow_area:hover .second_in:has(> div.shadow_top ){
          //   height: 0px !important;
          // }
        }
      }
    }
  }
  .cooperative_enterprise {
    color: white;
    width: 100%;
    height: 640px;
    background: url("../assets/imgs/index/xj_companys.png") no-repeat;
    background-size: cover;
    position: relative;
    .center {
      width: 1200px;
      position: absolute;
      margin: auto;
      left: 0px;
      right: 0px;
      top: 190px;
      h3 {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 60px;
      }
      .companys {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .company_box {
          width: 270px;
          // height: 130px;
          padding-top: 22px;
          padding-bottom: 24px;
          // box-sizing: border-box;
          text-align: center;
          margin: 0 21px 32px 0;
          background: rgba(255, 255, 255, 0.1);
          img {
            margin-bottom: 7px;
            cursor: default;
          }
        }
      }
    }
  }
  .common_width {
    width: 1200px !important;
  }
  .anotherItem .shadow_top {
    height: 132px !important;
  }
  .ct {
    font-size: 14px;
  }
  .first_in:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .first_in:nth-child(4) {
    margin-right: 16px;
  }
  .second_in {
    margin-top: 15px;
  }
  .third_in {
    margin-top: 15px;
  }
  .second_in:nth-child(2) {
    margin-left: 16px;
    margin-right: 16px;
  }
  .third_in:nth-child(1) {
    margin-right: 16px;
  }
  .third_in:nth-child(1) span {
    width: 100px !important;
  }
  .third_in:nth-child(2) span {
    width: 110px !important;
  }
  .btn_change:hover {
    cursor: pointer;
    background: #0f73ff !important;
    color: white !important;
    /* content: url("../assets/imgs/index/rightIcon.png"); */
  }
  .btn_change:hover .img_set {
    content: url("../assets/imgs/index/rightIcon.png");
  }
}
</style>
